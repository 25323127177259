import Modal from 'react-modal'
import classNames from 'classnames'

import { enablePageScroll, disablePageScroll } from 'lib/helpers/scroll_helpers'
import FadeInOut from 'lib/animations/FadeInOut'
import CloseButton from 'components/core/CloseButton'

import styles from './styles.module.scss'

export const CARD_ANIMATIONS = {
  SLIDE: 'slide',
}

const Card = ({
  overlayColor = null,
  width = null,
  minHeight = null,
  maxHeight = null,
  height = null,
  overflowX = 'auto',
  overflowY = 'auto',
  children = null,
  title = null,
  isCardOpen = false,
  onCloseCard = null,
  className = '',
  overlayClassName = '',
  closeButtonClassName = '',
  animation = CARD_ANIMATIONS.SLIDE,
  isStickyHeader = false,
  headerTitle = null,
  presentInCenter = false, // Doesn't play well with animations. Pass animation='none' if using this.
  contentClassName = '',
  headerClassName = '',
  headerTitleClassName = '',
  stickyHeaderCloseButtonClassName = '',
  subheaderTitle = '',
  subheaderTitleClassName = '',
  ...otherProps
}) => {
  return (
    <Modal
      className={classNames(styles.card__modal, className, {
        [styles['card__modal--in-center']]: presentInCenter,
      })}
      overlayClassName={classNames(styles.card__overlay, overlayClassName)}
      bodyOpenClassName={styles.card__body}
      portalClassName={styles[`card__${animation}`]}
      isOpen={isCardOpen}
      onAfterOpen={() => !otherProps.skipScrollDisabling && disablePageScroll()}
      onAfterClose={() => !otherProps.skipScrollDisabling && enablePageScroll()}
      onRequestClose={onCloseCard}
      style={{
        overlay: {
          backgroundColor: overlayColor,
        },
        content: { width, height, minHeight, maxHeight, overflowX, overflowY },
      }}
      contentLabel={title}
      closeTimeoutMS={300}
      {...otherProps}
    >
      <FadeInOut
        shouldShow={isCardOpen}
        appear
        shouldDelay
        className={classNames(styles.card__content, contentClassName)}
      >
        {isStickyHeader ? (
          <div className={classNames(styles['card__sticky-header'], headerClassName)}>
            <h2 className={classNames(styles['card__sticky-header-title'], headerTitleClassName)}>
              {headerTitle}
            </h2>

            {subheaderTitle && (
              <p
                className={classNames(
                  styles['card__sticker-header-subtitle'],
                  subheaderTitleClassName,
                )}
              >
                {subheaderTitle}
              </p>
            )}

            <CloseButton
              onClick={onCloseCard}
              className={classNames(
                styles['card__sticky-header-close-button'],
                stickyHeaderCloseButtonClassName,
              )}
            />
          </div>
        ) : (
          <CloseButton
            className={classNames(styles.card__closeButton, closeButtonClassName)}
            onClick={onCloseCard}
          />
        )}
        {children}
      </FadeInOut>
    </Modal>
  )
}

export default Card
