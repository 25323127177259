import { useState, useEffect } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'

import Button from '@everlane/button'
import isValidEmail from 'lib/helpers/verify_email'
import { publishEvent, subscribeToEvent, unsubscribeFromEvent } from 'lib/events/tracking'
import { SiteLocation, getSiteLocationForModals } from 'lib/siteLocation'
import useEmailAvailability from 'lib/network/hooks/useEmailAvailability'
import useUpdateVisitor from 'lib/network/hooks/useUpdateVisitor'
import Event from 'types/Event'

import styles from './styles.module.scss'

const EmailForm = ({
  bindEmail,
  handleCheckEmailAvailability,
  doNotRenderContent,
  setVendorEmail = null,
  setVendorSiteLocation = null,
  emailOptin,
}: {
  bindEmail: { value: any; onChange: (event: any) => void }
  handleCheckEmailAvailability: (arg0: boolean) => void
  doNotRenderContent?: boolean
  setVendorEmail?: (arg0: string) => void
  setVendorSiteLocation?: (arg0: string) => void
  emailOptin: boolean
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const { asPath } = useRouter()
  const siteLocation = getSiteLocationForModals(asPath)
  const { mutate: updateVisitor } = useUpdateVisitor()

  const { mutate: checkEmailAvailability } = useEmailAvailability({
    onCompleted: ({ isAvailable }) => {
      handleCheckEmailAvailability(isAvailable)
      setIsLoading(false)
    },
    onError: () => setIsLoading(false),
  })

  useEffect(() => {
    const handleWunderkindEmailEntered = ({ email, gender }) => {
      if (isValidEmail(email)) {
        setIsLoading(true)

        if (gender) {
          updateVisitor({ visitor: { gender } })
        }

        // the WK modal specifically mentions the 15% off so emailOptin should always be true
        checkEmailAvailability({
          email,
          emailOptin: true,
          siteLocation: SiteLocation.WUNDERKIND_MODAL,
        })

        setVendorEmail(email)
        publishEvent(Event.User.EMAIL_OPT_IN)
        setVendorSiteLocation(SiteLocation.WUNDERKIND_MODAL)
      } else {
        setError('Please enter a valid email.')
      }
    }

    subscribeToEvent(Event.Wunderkind.EMAIL_ENTERED, handleWunderkindEmailEntered)

    return () => {
      unsubscribeFromEvent(Event.Wunderkind.EMAIL_ENTERED, handleWunderkindEmailEntered)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (doNotRenderContent) return null

  const handleSubmit = event => {
    event.preventDefault()
    if (isValidEmail(bindEmail.value)) {
      setIsLoading(true)
      checkEmailAvailability({ email: bindEmail.value, emailOptin, siteLocation })

      publishEvent(Event.Login.EMAIL_ENTERED, {
        site_location: siteLocation,
      })
    } else {
      setError('Please enter a valid email.')
    }
  }

  return (
    <form className={styles['email-form']} onSubmit={handleSubmit}>
      <label className={styles['email-form__label']} htmlFor="email">
        <input
          className={classNames(styles['email-form__input'], {
            [styles['email-form__input--error']]: error,
          })}
          name="email"
          type="email"
          placeholder="Email Address"
          autoComplete="email"
          {...bindEmail}
        />
        {error && <div className={styles['email-form__error']}>{error}</div>}
      </label>

      <Button
        variant="primary"
        className={styles['email-form__submit-button']}
        onClick={handleSubmit}
        disabled={isLoading || bindEmail.value.length === 0}
      >
        {isLoading ? 'Loading…' : 'Sign Up'}
      </Button>
    </form>
  )
}

export default EmailForm
