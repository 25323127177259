import { useState, useEffect } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import Cookie from 'js-cookie'
import Button from '@everlane/button'
import dynamic from 'next/dynamic'

import useIsMobile from 'lib/hooks/useIsMobile'
import useInput from 'lib/hooks/useInput'
import useSession from 'lib/network/hooks/useSession'
import { publishEvent } from 'lib/events/tracking'
import { getErrorMessage } from 'lib/helpers/errors'
import { getSiteLocationForModals } from 'lib/siteLocation'

import { Media } from 'components/core/BreakpointMedia'

import Card from 'components/core/Card'
import Image from 'components/core/Image'
import EmailForm from 'components/auth/EmailForm'
import SocialConnectButtons from 'components/auth/SocialConnectButtons'
import PolicyAndTerms from 'components/auth/PolicyAndTerms'
import AuthModalCopy from 'components/auth/AuthModal/AuthModalCopy'

import Event from 'types/Event'
import Cookies from 'types/Cookies'

import { CopyType, CopyTypes, ModalStates } from './CopyTypes'
import SwitchToSignupText from './SwitchToSignupText'
import SwitchToLoginText from './SwitchToLoginText'

import styles from './styles.module.scss'

const SignUpForm = dynamic(() => import('components/auth/SignupForm'), { ssr: false })
const ResetPasswordForm = dynamic(() => import('components/auth/ResetPasswordForm'), { ssr: false })
const LoginForm = dynamic(() => import('components/auth/LoginForm'), { ssr: false })
const SignupSuccessModal = dynamic(() => import('components/auth/SignupForm/SignupSuccessModal'), {
  ssr: false,
})
const WOMENS_SIGNING_IN_IMAGE_SRC = 'signup-modal.jpg'
const MENS_SIGNING_IN_IMAGE_SRC = 'i/4c52d9ee_9ec1.jpg'
const SIGNING_UP_IMAGE_SRC = 'signup-modal.jpg'

const AuthModal = ({
  isOpen = false,
  closeModal = null,
  visitorEmail = null,
  newUserEmail = null,
  modalState = null,
}: {
  isOpen?: boolean
  closeModal?: Function
  visitorEmail?: string
  newUserEmail?: string
  modalState?: string
}) => {
  const isMobile = useIsMobile()
  const { data: session } = useSession()
  const { value: email, bind: bindEmail } = useInput()
  const [emailSubmitted, setEmailSubmitted] = useState(false)
  const [isExistingUser, setIsExistingUser] = useState(null)
  const [genericError, setGenericError] = useState(null)
  const [isPasswordReset, setIsPasswordReset] = useState(false)
  const [isPasswordResetCompleted, setIsPasswordResetCompleted] = useState(false)
  const [override, setOverride] = useState(null)
  const [hasSignedUp, setHasSignedUp] = useState(false)
  const [vendorEmail, setVendorEmail] = useState(null)
  const [vendorSiteLocation, setVendorSiteLocation] = useState(null)
  const [authModalImage, setAuthModalImage] = useState<string>()
  const { asPath } = useRouter()
  const siteLocation = getSiteLocationForModals(asPath)

  const switchToLogin = () => {
    setEmailSubmitted(true)
    setIsExistingUser(true)
    setOverride(null)
    publishEvent(Event.Login.SWITCH_TO_SIGN_IN, {
      site_location: siteLocation,
    })
  }

  const isRepeatUser = !!Cookie.get(Cookies.EVERLANE_USER)
  useEffect(() => {
    if (isRepeatUser && visitorEmail?.length > 0) {
      setEmailSubmitted(true)
      setIsExistingUser(true)
    }
  }, [isRepeatUser, visitorEmail])

  useEffect(() => {
    setOverride(modalState)
  }, [modalState])

  useEffect(() => {
    if (newUserEmail) {
      setEmailSubmitted(true)
    }
  }, [newUserEmail])

  useEffect(() => {
    if (!isExistingUser) {
      setAuthModalImage(SIGNING_UP_IMAGE_SRC)
    } else if (session?.visitor?.gender === 'male') {
      setAuthModalImage(MENS_SIGNING_IN_IMAGE_SRC)
    } else {
      setAuthModalImage(WOMENS_SIGNING_IN_IMAGE_SRC)
    }
  }, [isExistingUser, modalState, session])

  useEffect(() => {
    if (isOpen) {
      publishEvent(Event.Login.MODAL_VIEW, {
        site_location: siteLocation,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    if (hasSignedUp) {
      publishEvent(Event.User.EMAIL_OPT_IN)
    }
  }, [hasSignedUp])

  const renderLoginOrSignup = isAvailable => {
    setEmailSubmitted(true)
    setIsPasswordReset(false)
    setIsPasswordResetCompleted(false)
    setIsExistingUser(!isAvailable)
    setOverride(null)
  }

  const determineCopyType = () => {
    if (isPasswordReset) return CopyTypes.PASSWORD_RESET
    if (isPasswordResetCompleted) return CopyTypes.PASSWORD_RESET_COMPLETED
    if (override === ModalStates.LOG_IN || (emailSubmitted && isExistingUser))
      return CopyTypes.LOGIN
    if (override === ModalStates.SIGN_UP || emailSubmitted) return CopyTypes.SIGN_UP
    if (!isRepeatUser) return CopyTypes.NEW_USER_PROMO
    return CopyTypes.EMAIL
  }

  const closeAuthModal = () => {
    closeModal()

    // to account for the 300ms delay for the animation
    setTimeout(() => {
      setEmailSubmitted(false)
      setIsPasswordReset(false)
      setIsPasswordResetCompleted(false)
      setIsExistingUser(null)
      setHasSignedUp(false)
    }, 300)
  }

  const isSigningUp =
    override === ModalStates.SIGN_UP ||
    (emailSubmitted &&
      !isExistingUser &&
      !isPasswordReset &&
      !isPasswordResetCompleted &&
      override !== ModalStates.LOG_IN)
  const isLoggingIn =
    override === ModalStates.LOG_IN ||
    (emailSubmitted &&
      isExistingUser &&
      !isPasswordReset &&
      !isPasswordResetCompleted &&
      override !== ModalStates.SIGN_UP)

  if (hasSignedUp) {
    return <SignupSuccessModal closeAuthModal={closeAuthModal} />
  }

  const shouldSpanFullHeight = !isLoggingIn && !isPasswordReset && isMobile
  const isInitialLandingModal = !isLoggingIn && !isSigningUp && !isPasswordReset

  if (isInitialLandingModal && !override) {
    return (
      <EmailForm
        doNotRenderContent
        emailOptin
        setVendorEmail={setVendorEmail}
        setVendorSiteLocation={setVendorSiteLocation}
        bindEmail={bindEmail}
        handleCheckEmailAvailability={isAvailable => {
          renderLoginOrSignup(isAvailable)
          if (isAvailable) {
            publishEvent(Event.Login.SHOW_SIGN_UP_VIEW, {
              site_location: siteLocation,
            })
          } else
            publishEvent(Event.Login.SHOW_SIGN_IN_VIEW, {
              site_location: siteLocation,
            })
        }}
      />
    )
  }

  const renderModalContent = () => {
    if (isPasswordReset) {
      return (
        <ResetPasswordForm
          bindEmail={bindEmail}
          onSuccess={() => {
            setIsPasswordReset(false)
            setIsPasswordResetCompleted(true)
            publishEvent(Event.Login.SUBMIT_RESET_PASSWORD, { site_location: siteLocation })
          }}
        />
      )
    }

    if (isPasswordResetCompleted) {
      return (
        <div>
          <Button
            type="button"
            variant="primary"
            className={styles['auth-modal__switch-to-login-button']}
            onClick={() => renderLoginOrSignup(false)}
          >
            Go Back To Login
          </Button>
        </div>
      )
    }

    return (
      <>
        {!emailSubmitted && !override && (
          <EmailForm
            bindEmail={bindEmail}
            handleCheckEmailAvailability={isAvailable => {
              renderLoginOrSignup(isAvailable)
              if (isAvailable) {
                publishEvent(Event.Login.SHOW_SIGN_UP_VIEW, {
                  site_location: siteLocation,
                })
              } else {
                publishEvent(Event.Login.SHOW_SIGN_IN_VIEW, {
                  site_location: siteLocation,
                })
              }
            }}
            emailOptin={determineCopyType() === CopyTypes.NEW_USER_PROMO}
          />
        )}
        {isLoggingIn && (
          <LoginForm initialEmail={email || visitorEmail || vendorEmail} onSuccess={closeModal} />
        )}
        {isSigningUp && (
          <SignUpForm
            siteLocation={vendorSiteLocation || siteLocation}
            onSuccess={() => setHasSignedUp(true)}
            initialEmail={email || newUserEmail || vendorEmail}
            switchToLogin={switchToLogin}
          />
        )}
        {isLoggingIn && (
          <div className={styles['auth-modal__social-container']}>
            <SocialConnectButtons
              siteLocation={siteLocation}
              handleError={err => setGenericError(getErrorMessage(err))}
              handleSuccess={closeModal}
            />
          </div>
        )}
        {isLoggingIn && (
          <SwitchToSignupText
            switchToSignup={() => {
              renderLoginOrSignup(true)
              publishEvent(Event.Login.SWITCH_TO_SIGN_UP, {
                site_location: siteLocation,
              })
            }}
          />
        )}
        {isInitialLandingModal && (
          <p className={styles['auth-modal__continue-shopping']}>
            <button
              className={styles['auth-modal__continue-shopping--button']}
              type="button"
              onClick={closeAuthModal}
            >
              Continue Shopping
            </button>
          </p>
        )}
        {!emailSubmitted && !override && <SwitchToLoginText switchToLogin={switchToLogin} />}
        {isLoggingIn && (
          <div className={styles['auth-modal__reset-password-link-wrapper']}>
            <button
              className={classNames(
                styles['auth-modal__copy-link'],
                styles['auth-modal__reset-password-link'],
              )}
              type="button"
              onClick={() => {
                setIsPasswordReset(true)
                publishEvent(Event.Login.SHOW_RESET_PASSWORD, { site_location: siteLocation })
              }}
            >
              Reset password
            </button>
          </div>
        )}
        {isSigningUp && <PolicyAndTerms />}
      </>
    )
  }

  return (
    <Card
      isCardOpen={isOpen}
      className={classNames(styles['auth-modal'], {
        [styles['auth-modal--reset-pw']]: isPasswordReset || isPasswordResetCompleted,
        [styles['auth-modal--sign-up-form']]: isSigningUp,
        [styles['auth-modal--sign-in-form']]: isLoggingIn,
      })}
      closeButtonClassName={classNames(styles['auth-modal__close-button'], {
        [styles['auth-modal__close-button--pw-reset']]: isPasswordReset || isPasswordResetCompleted,
      })}
      animation={null}
      onCloseCard={() => {
        publishEvent(Event.Login.MODAL_CLOSED, {
          site_location: siteLocation,
        })
        closeAuthModal()
      }}
      minHeight="0"
      height={shouldSpanFullHeight ? '96%' : null}
      maxHeight="96%"
    >
      <div className={styles['auth-modal__body']}>
        <div
          className={classNames(styles['auth-modal__body-content'], {
            [styles['auth-modal__body-content--pw-reset']]:
              isPasswordReset || isPasswordResetCompleted,
          })}
        >
          <AuthModalCopy copyType={determineCopyType() as CopyType} email={email} />
          {genericError && (
            <div className={styles['auth-modal__generic-error']}>{genericError}</div>
          )}
          <div className={styles['auth-modal__form-wrapper']}>{renderModalContent()}</div>
        </div>
        {!isPasswordReset && !isPasswordResetCompleted && (
          <Media greaterThanOrEqual="medium">
            <Image
              className={styles['auth-modal__image']}
              containerClassName={styles['auth-modal__image-container']}
              src={authModalImage}
              alt=""
              width={400}
              height={625}
              sizes="382px"
            />
          </Media>
        )}
      </div>
    </Card>
  )
}

export default AuthModal
