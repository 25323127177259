import styles from './styles.module.scss'

const SwitchToSignupText = ({ switchToLogin }: { switchToLogin: () => void }) => {
  return (
    <p className={styles['auth-modal__copy-subheader--center']}>
      Already have an account?
      <button
        className={styles['auth-modal__copy-link--signin']}
        type="button"
        onClick={switchToLogin}
      >
        Sign in
      </button>
    </p>
  )
}

export default SwitchToSignupText
