import React from 'react'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'

import styles from './styles.module.scss'

const FadeInOut = ({
  shouldShow = true,
  appear = false,
  timeout = 400,
  children,
  className,
  shouldDelay,
  key,
}: {
  shouldShow?: boolean
  appear?: boolean
  timeout?: number
  children: React.ReactNode
  className?: string
  shouldDelay?: boolean
  key?: string
}) => {
  return (
    <CSSTransition
      key={key}
      in={shouldShow}
      appear={appear}
      timeout={timeout}
      classNames={shouldDelay ? 'fade-in-out--delay' : 'fade-in-out'}
      className={classNames(className, {
        [styles['fade-in-out']]: !shouldDelay,
        [styles['fade-in-out--delay']]: shouldDelay,
      })}
      unmountOnExit
    >
      <div>{children}</div>
    </CSSTransition>
  )
}

export default FadeInOut
