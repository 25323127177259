import classNames from 'classnames'

import EverlaneLogo from 'components/Layout/Navbar/EverlaneLogo'
import { CopyType, CopyTypes } from './CopyTypes'

import styles from './styles.module.scss'

const EmailCopy = () => {
  return (
    <div className={styles['auth-modal__copy']}>
      <p className={styles['auth-modal__copy-header']}>Your first order ships free</p>
      <p className={styles['auth-modal__copy-subheader']}>
        Exceptional quality. Ethical factories. Radical Transparency. Enjoy free U.S. shipping. and
        returns on your first order.
      </p>
    </div>
  )
}

const LoginCopy = () => {
  return (
    <div className={styles['auth-modal__copy']}>
      <p className={styles['auth-modal__copy-header']}>Good to see you again.</p>
      <p className={styles['auth-modal__copy-subheader']}>Please log in to your account.</p>
    </div>
  )
}

const SignupCopy = () => {
  return (
    <div
      className={classNames(styles['auth-modal__copy'], styles['auth-modal__copy--full-screen'])}
    >
      <EverlaneLogo height="12px" className={styles['auth-modal__everlane-logo']} />
      <p className={styles['auth-modal__copy-header']}>Create a password</p>
      <p className={styles['auth-modal__copy-subheader']}>
        Complete your sign up to <br />
        receive your discount.*
      </p>
    </div>
  )
}

const PasswordResetCopy = () => {
  return (
    <div className={styles['auth-modal__copy']}>
      <p
        className={classNames(
          styles['auth-modal__copy-header'],
          styles['auth-modal__copy-header--pw-reset'],
        )}
      >
        Reset Password
      </p>
      <p className={styles['auth-modal__copy-subheader']}>
        Enter the email address associated with your account to receive a link to reset your
        password.
      </p>
    </div>
  )
}

const PasswordResetCompletedCopy = ({ email }: { email: string }) => {
  return (
    <div className={styles['auth-modal__copy']}>
      <p
        className={classNames(
          styles['auth-modal__copy-header'],
          styles['auth-modal__copy-header--pw-reset'],
        )}
      >
        Check your email
      </p>
      <p className={styles['auth-modal__copy-subheader']}>
        We sent an email to {email} with the link to reset your password. If you don&rsquo;t see
        this email, please check your spam folder.
      </p>
    </div>
  )
}

const NewUserPromoCopy = () => {
  return (
    <div className={styles['auth-modal__copy']}>
      <EverlaneLogo height="12px" className={styles['auth-modal__everlane-logo']} />
      <p className={styles['auth-modal__copy-header--new-user']}>
        <span>Create a password</span>
      </p>
      <p className={styles['auth-modal__copy-subheader--new-user']}>
        <span>
          Complete your sign up to <br />
          receive your discount.*
        </span>
      </p>
    </div>
  )
}

const AuthModalCopy = ({ copyType, email }: { copyType: CopyType; email: string }) => {
  return (
    <div>
      {copyType === CopyTypes.EMAIL && <EmailCopy />}
      {copyType === CopyTypes.LOGIN && <LoginCopy />}
      {copyType === CopyTypes.SIGN_UP && <SignupCopy />}
      {copyType === CopyTypes.PASSWORD_RESET && <PasswordResetCopy />}
      {copyType === CopyTypes.PASSWORD_RESET_COMPLETED && (
        <PasswordResetCompletedCopy email={email} />
      )}
      {copyType === CopyTypes.NEW_USER_PROMO && <NewUserPromoCopy />}
    </div>
  )
}

export default AuthModalCopy
