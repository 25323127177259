import classNames from 'classnames'
import styles from './styles.module.scss'

const SwitchToSignupText = ({ switchToSignup }: { switchToSignup: () => void }) => {
  return (
    <p className={styles['auth-modal__copy-subheader--center']}>
      Don&rsquo;t have an account?
      <button
        className={classNames(
          styles['auth-modal__copy-link'],
          styles['auth-modal__copy-link--signup'],
        )}
        type="button"
        onClick={switchToSignup}
      >
        Sign up
      </button>
    </p>
  )
}

export default SwitchToSignupText
